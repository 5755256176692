/*
 * this file is for definition of constants for customers
 * e.g. for defining project phases/timelines for switching functions on/off
 *
 * NOTE: circular imports may lead to "ReferenceError: window is not defined"
 * NOTE imports of enums lead to ReferenceError: Cannot access <enum> before initialization: trying to read <enum>.<entry>
 * That's why most customer configurations are not typed with complex types but as string values or similar
 * @todo https://futureprojects.atlassian.net/browse/FCP-1651
 */
import { BoundsLiteral } from "@components/common/map/mapHelper"
import { TimelinePhase, getLatestTimelinePhase, StringBasedStringArray } from "@services/util"

/** customer timeline */
const customerTimeline: TimelinePhase[] = [
  // { startDate: "2023-08-30", phase: 2 },
]

const currentPhase = getLatestTimelinePhase(customerTimeline)

// NOTE: put in the real env-var name!
export const CUSTOMER_PHASE: number = currentPhase?.phase
  ? currentPhase.phase
  : parseInt(process.env.CUSTOMER_PHASE, 10)


// #region partnermarket

/**
 * Which style should the SupportRequestCard have?
 * By default: SUPPORT_REQUEST_CARD_PROJEKTFABRIK_STYLE
 * Every other value leads to a "simple version"
 */
// as string and not as enum or other imported variable,
// b/c: ReferenceError: Cannot access 'SUPPORT_REQUEST_CARD_PROJEKTFABRIK_STYLE' before initialization
// or
// ReferenceError: Cannot access <enum> before initialization: trying to read <enum>.<entry>
// @todo: https://futureprojects.atlassian.net/browse/FCP-1329
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const SUPPORT_REQUEST_CARD_STYLE: string = "SUPPORT_REQUEST_CARD_PROJEKTFABRIK_STYLE"

/**
 * order of the SupporterRole Filter in the Partnermarket
 * and when creating a SupportRequest
 */
export const SUPPORTER_ROLE_ORDER_AS_FILTER: string[] = [
  'sponsor',
  'supplier',
  'feedback_provider',
  'expert',
  'ambassador',
  'contributor',
]

/**
 * available feedback post types for the customer when creating a discussion/post
 * in the wanted order, based on FeedbackPostType
 *
 * set to null if all default types shall be used
 */
export const CUSTOMER_FEEDBACK_POST_TYPES: string[] = null
// [
//   'hint',
//   'criticism',
//   // 'troll', // no trolling for cottbus
//   'question',
//   'support'
// ]

/**
 * should the networking dashboard be used?
 *
 * NOTE: there must be at least an link/access to the SupportRequests, if the partnermarket is available
 * and USE_NETWORKING_DASHBOARD === false
 */
export const USE_NETWORKING_DASHBOARD = true


// #endregion partnermarket


/**
 * Alternative routes to be processed by the relevant pages to forward the user instead of
 * showing their content.
 *
 * NOTE: currently only implemented in pages Imprint + DataProtection
 *
 * @todo: https://futureprojects.atlassian.net/browse/FCP-1438
 */
export const ALTERNATIVE_ROUTES: StringBasedStringArray = {
  // [Routes.Imprint]: "https://sinn-sachsen.de/impressum.html#Impressum",
  // [Routes.DataProtection]: "https://sinn-sachsen.de/datenschutz.html#Datenschutz",
}

// #region social media links
// store URLs in those variables to configure the social media icons
/**
 * URL to a Facebook page of the current customer
 */
export const SOCIAL_MEDIA_FACEBOOK: string = null
/**
 * URL to a Instagram page of the current customer
 */
export const SOCIAL_MEDIA_INSTAGRAM: string = null
// #endregion social media links


/**
 * enables/disables the option that a processmanager is able to edit a project's profile
 */
export const ENABLE_MANAGER_EDIT_PROJECT_PROFILE = false

// #region map

/**
 * Map: which drawing options should be provided to the project team?
 * options: marker, polyline, polygon
 */
export const CUSTOMER_PROJECT_MAP_DRAW_FUNCTIONS: string[] = ["marker", "polygon", /* polyline */]

/**
 * what area of the map is shown by default?
 * Dresden: [51.0504, 13.7373]
 * Saxony: [[50.5, 12.5],  [51.5, 14.5]]
 */
// The usage of this imported type may fail, when the file, where the type is located
// imports this config-file: it results in a circular connection!
export const CUSTOMER_MAP_BOUNDS: BoundsLiteral = [
  [50.5, 12.5],
  [51.5, 14.5]
]

// #endregion map